<template>
	<router-view/>
	<v-loader/>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import VLoader from "../Components/VLoader.vue";

export default defineComponent({
	name: "App",
	components: {VLoader},
});
</script>

<style scoped>
</style>
