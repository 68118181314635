<template>
	<div id="v-loader-component" class="v-loader__overlay">
		<div class="v-loader__container">
			<i class="fa fa-spinner fa-3x fa-spin"></i>
			<p class="mt-4">
				Betöltés folyamatban...
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
	name: "VLoader",
});
</script>

<style scoped>
</style>
