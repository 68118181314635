import {defineStore} from 'pinia';
import axios from "axios";
import Cookie from 'js-cookie';

import {AuthUserInterface} from './Interfaces/AuthUserInterface';
import {AuthLoginInterface} from './Interfaces/AuthLoginInterface';
import {urls} from './Utils/AuthUrls';

export const useAuthStore = defineStore({
	id: 'AuthStore',
	state: () => ({
		loggedIn: !!Cookie.get('jwt_session'),
		user: {} as AuthUserInterface,
		loaded: false,
	}),
	actions: {
		async loginRequest(email: string, password: string, token: string) {
			// Get laravel csrf token for sanctum login
			await getCSRF();
			// If i have csrf token in cookie then we login laravel
			const loginData = <AuthLoginInterface>{email: email, password: password, reCaptchaToken: token};
			return await axios.post(urls.login, loginData);
		},
		async login(email: string, password: string, token: string) {
			return await this.loginRequest(email, password, token).then(({data}) => {
				if (data) {
					this.setToken(data.token);
					this.user = data.user;
				}
			});
		},
		async getMe() {
			return await axios.get(urls.getMe)
				.then(response => {
					if (response.status === 200 && response.data.user !== null) {
						this.user = response.data.user;
						this.loggedIn = true;
						this.loaded = true;

					}
				})
		},
		async logout() {
			return await axios.get(urls.logout)
				.then(response => {
					if (response.status === 200) {
						this.removeToken();
						this.user = <AuthUserInterface>{};
						this.loggedIn = false;
						this.loaded = false;
					}
				})
		},
		setToken(token: string) {
			try {
				this.loggedIn = true;
				Cookie.set('jwt_session', btoa(token));
				axios.defaults.headers.common["Authorization"] = 'Bearer ' + atob(<string>Cookie.get('jwt_session'));
			} catch (error) {
				// Ez van
			}

		},
		removeToken() {
			this.loggedIn = false;
			Cookie.remove('jwt_session');
			delete axios.defaults.headers.common["Authorization"];
		},

	},
	getters: {
		isLoggedIn: state => state.loggedIn,
		currentUser: state => state.user,
		isLoaded: state => state.loaded
	}
})

export async function getCSRF() {
	return await axios.get(urls.getCSRFToken);
}

