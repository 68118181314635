import axios from 'axios';
import SweetAlert from "sweetalert2";
import Cookie from "js-cookie";
import router from "./router.ts";

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// Admin website secure
if (Cookie.get('jwt_session')) {
	window.axios.defaults.headers.common["Authorization"] = 'Bearer ' + atob(Cookie.get('jwt_session'));
}

// Axios Interceptors Request
window.axios.interceptors.request.use(function (request) {
	try {
		document.querySelector('#v-loader-component').classList.add('show');
	} catch (err) {
		// EZ van
	}
	return request;
});
// Axios Interceptors Response
window.axios.interceptors.response.use(function (response) {
	try {
		document.querySelector('#v-loader-component').classList.remove('show');
	} catch (err) {
		// EZ van
	}
	return response;
}, function (error) {
	switch (error.response.status) {
		case 403:
			SweetAlert.fire('', 'Nincs jogosultságod megtekinteni az oldalt!', 'error');
			router.push({name: 'dashboard'});
			break;
		case 401:
			Cookie.remove('jwt_session');
			location.href = '/';
			break;
	}
	try {
		document.querySelector('#v-loader-component').classList.remove('show');
	} catch (err) {
		// EZ van
	}
	return Promise.reject(error)
});
