import * as VueRouter from 'vue-router';
// @ts-ignore
import {useAuthStore} from "./Stores/Auth/AuthStore";
import Sweetalert2 from "sweetalert2";

// Layout Pages
const LoginPage = () => import('./Layouts/Login/LoginPage.vue');
const RegisterPage = () => import('./Layouts/Register/RegisterPage.vue');
const AdminLayout = () => import('./Layouts/AdminLayout/AdminLayout.vue');
const DashboardView = () => import('./DashboardModule/DashboardView.vue');
const TokenModule = () => import('./Views/Tokens/TokenModule.vue');
const ActivityModuleView = () => import('./Views/ActivityModule/ActivityModuleView.vue');

// Multi Module Frontend
const DynamicAdminModule = () => import('./DynamicModule/DynamicAdminModule.vue');
const routes = [
	// Logged in views
	{
		path: '/login',
		name: 'login',
		component: LoginPage,
	},
	///////////////////////////////////////////////
	// 	Register Vue
	///////////////////////////////////////////////
	{
		path: '/register',
		name: "register",
		component: RegisterPage,
	},
	{
		path: '/',
		component: AdminLayout,
		meta: {
			auth: true
		},
		children: [
			{
				path: '/dashboard',
				name: 'dashboard',
				component: DashboardView,
			},
			{
				path: '/admins',
				name: 'admins',
				alias: [
					'/admins/:id'
				],
				meta: {
					config: {
						name: 'Adminisztrátorok',
						sidebar: 'list',
						localize: false,
						breadcrumbs: [
							'Adminisztrátorok',
							'Listázás'
						],
						fields: [
							{
								type: 'input',
								title: 'Teljes név',
								name: 'name',
							},
							{
								type: 'input',
								title: 'Email',
								name: 'email',
								options: {},
							},
							{
								type: 'input',
								title: 'Jelszó',
								name: 'password',
								options: {
									onlyEdit: true,
									type: 'password'
								}
							},
							{
								type: 'checkbox',
								title: 'Aktív',
								name: 'status',
								options: {
									onlyEdit: true,
								},
							},
						],
						urls: {
							list: '/api/admins/list',
							store: '/api/admins/invite',
							action: '/api/admins/',
						}
					}
				},
				component: DynamicAdminModule,
			},
			{
				path: '/tokens',
				name: 'tokens',
				alias: [
					'/tokens/:id'
				],
				component: TokenModule,
			},
			{
				path: 'activities',
				name: 'activities',
				component: ActivityModuleView
			},
		]
	},
	{path: '/:pathMatch(.*)*', name: '404', redirect: '/'},
]

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes,
});

router.beforeEach(async (to, _from, next) => {
	const store = useAuthStore();

	if ((to.name !== 'login' && to.name !== 'register') && !store.isLoggedIn) {
		next({name: "login"});
	} else if ((to.name === 'login' || to.name === 'register') && store.isLoggedIn) {
		next({name: 'dashboard'});
	} else if (store.isLoggedIn === false && to.meta.auth === true) {
		await Sweetalert2.fire({
			title: 'Kérlek jelentkezz be az oldal megtekintéséhez!',
			icon: 'warning',
			confirmButtonColor: 'var(--colorMain)',
			confirmButtonText: 'OK'
		});
		await router.push({name: 'home'});
		return;
	}
	// Otherwise allowed viewing.
	if (store.isLoggedIn && !store.isLoaded) {
		await store.getMe();
	}
	next();
});

export default router;
