import './bootstrap.js';
// @ts-ignore
import {createApp, h} from 'vue/dist/vue.esm-bundler';
import {createPinia} from 'pinia';

import App from "./Layouts/App.vue";
import router from "./router";
import {VueReCaptcha} from "vue-recaptcha-v3";
import mitt from "mitt";
import vSelect from "vue-select";
import Maska from 'maska'
// @ts-ignore
const env = import.meta.env;

const app = createApp({
	render: () => h(App),
	data() {
		return {
			editorApiKey: 'oj712afrwwg4lf2uucj7uaky93rr88z09tr5g2aq0pm455hh',
			editorConfig: {
				menubar: false,
				image_class_list: [
					{title: 'Responsive', value: 'tiny-img-responsive'}
				],
				entity_encoding: "raw",
				forced_root_block: "",
				plugins: 'lists link image emoticons table code',
				toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons table code || inserter',
				statusbar: false,
				height: "480",
				image_title: true,
				automatic_uploads: true,
				file_picker_types: 'image',
				file_picker_callback: (cb: any): void => {
					let input = document.createElement('input');
					input.setAttribute('type', 'file');
					input.setAttribute('accept', 'image/*');

					input.onchange = function () {
						// @ts-ignore
						let file = this.files[0];
						console.log(file.type);
						let reader: FileReader = new FileReader();

						if(!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(file.type)) {
							return false;
						}
						if(Math.round((file.size / 1024)) > 3072) {
							return false;
						}

						reader.onload = (): void => {
							let id: string = 'blobid' + (new Date()).getTime();
							// @ts-ignore
							let blobCache = tinymce.activeEditor.editorUpload.blobCache;
							// @ts-ignore
							let base64 = reader.result.split(',')[1];
							let blobInfo = blobCache.create(id, file, base64);
							blobCache.add(blobInfo);
							cb(blobInfo.blobUri(), { title: file.name });
						};
						reader.readAsDataURL(file);
					};
					input.click();
				},
			}
		}
	}
});


app.use(createPinia());
app.use(router);
app.use(Maska)
// @ts-ignore
app.use(VueReCaptcha, {siteKey: import.meta.env.VITE_RECAPTCHAV3_SITEKEY});

export const emitter = mitt();
app.provide('emitter', emitter);

app.component('v-select', vSelect);

app.mount("#app");
